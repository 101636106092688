<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link :to="{ name: 'dashboard' }" class="logo logo-dark">
            <span class="logo-sm">
              <h2 class="text-dark py-3">QPRO</h2>
            </span>
            <span class="logo-lg">
              <h2 class="text-dark py-3">QuestõesPRO</h2>
            </span>
          </router-link>

          <router-link :to="{ name: 'dashboard' }" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.png" alt height="18" />
            </span>
          </router-link>
        </div>

        <button
          type="button"
          class="btn btn-sm mr-2 font-size-24 d-lg-none header-item"
          @click="toggleMenu"
        >
          <i class="mdi mdi-menu"></i>
        </button>
      </div>

      <div class="d-flex">
        <div class="dropdown d-none d-lg-inline-block">
          <button
            type="button"
            class="btn header-item noti-icon"
            @click="initFullScreen"
            title="Ir para a tela cheia"
          >
            <i class="mdi mdi-fullscreen"></i>
          </button>
        </div>

        <NotificationList />

        <b-dropdown
          v-if="currentUser"
          class="d-inline-block"
          right
          toggle-class="header-item"
          variant="white"
        >
          <template v-slot:button-content>
            <img
              class="rounded-circle header-profile-user"
              :src="
                currentUser.avatar
                  ? $getImageUrl(currentUser.avatar.originalName)
                  : $defaultUserAvatar
              "
              alt="Header Avatar"
            />
          </template>
          <router-link
            :to="{
              name: 'profile',
              params: { username: currentUser.username },
            }"
            class="dropdown-item"
          >
            <i
              class="mdi mdi-account-circle font-size-17 align-middle mr-1"
            ></i>
            Perfil
          </router-link>
          <router-link
            :to="{
              name: 'myInterests',
            }"
            class="dropdown-item"
          >
            <i class="mdi mdi-view-list font-size-17 align-middle mr-1"></i>
            Meus Interesses
          </router-link>
          <router-link :to="{ name: 'editProfile' }" class="dropdown-item">
            <i
              class="mdi mdi-pencil-outline font-size-17 align-middle mr-1"
            ></i>
            Editar Perfil
          </router-link>
          <router-link :to="{ name: 'plan' }" class="dropdown-item">
            <i
              class="mdi mdi-coin-outline font-size-17 align-middle mr-1"
            ></i>
            Planos
          </router-link>

          <div class="dropdown-divider"></div>
          <router-link
            :to="{ name: 'logout' }"
            class="dropdown-item text-danger"
          >
            <i class="mdi mdi-logout align-middle mr-1 text-danger"></i>
            Logout
          </router-link>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";

import NotificationList from "@/components/NotificationList";

export default {
  name: "HorizontalTobar",
  components: {
    NotificationList,
  },

  computed: {
    ...mapState("auth", ["currentUser"]),
  },

  methods: {
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleMenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
  },
};
</script>
