<template>
  <div class="topnav">
    <div class="container-fluid">
      <nav class="navbar navbar-expand-lg topnav-menu">
        <div class="collapse navbar-collapse" id="topnav-menu-content">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link
                tag="a"
                class="nav-link side-nav-link"
                :to="{ name: 'dashboard' }"
              >
                <i class="mdi mdi-home mdi-16px mr-2"></i>
                Página inicial
              </router-link>
            </li>

            <li
              v-if="
                currentUser &&
                ['admin', 'content-manager', 'reviewer'].includes(
                  currentUser.role
                )
              "
              class="nav-item dropdown"
            >
              <a
                class="nav-link dropdown-toggle arrow-none"
                href="javascript: void(0);"
                id="topnav-layout"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="onMenuClick"
              >
                <i class="ti-bookmark-alt mr-2"></i>Administração
              </a>
              <div class="dropdown-menu" aria-labelledby="topnav-layout">
                <router-link
                  v-if="
                    currentUser &&
                    ['admin', 'content-manager'].includes(currentUser.role)
                  "
                  tag="a"
                  :to="{ name: 'listInterests' }"
                  class="dropdown-item side-nav-link"
                >
                  Gerenciar interesses
                </router-link>

                <router-link
                  v-if="
                    currentUser &&
                    ['admin', 'content-manager', 'reviewer'].includes(
                      currentUser.role
                    )
                  "
                  tag="a"
                  class="dropdown-item side-nav-link"
                  :to="{ name: 'manageQuestions' }"
                >
                  Gerenciar conteúdo
                </router-link>
                <router-link
                  v-if="
                    currentUser &&
                    ['admin'].includes(
                      currentUser.role
                    )
                  "
                  tag="a"
                  class="dropdown-item side-nav-link"
                  :to="{ name: 'manageCashback' }"
                >
                  Gerenciar cashback
                </router-link>
                <router-link
                  v-if="
                    currentUser &&
                    ['admin'].includes(
                      currentUser.role
                    )
                  "
                  tag="a"
                  class="dropdown-item side-nav-link"
                  :to="{ name: 'manageReferral' }"
                >
                  Gerenciar pagamento de indicações
                </router-link>
                <router-link
                  v-if="
                    currentUser &&
                    ['admin'].includes(
                      currentUser.role
                    )
                  "
                  tag="a"
                  class="dropdown-item side-nav-link"
                  :to="{ name: 'importSpreadsheet' }"
                >
                  Importar planilha
                </router-link>
              </div>
            </li>

            <li class="nav-item">
              <router-link
                tag="a"
                class="nav-link side-nav-link"
                :to="{ name: 'questions' }"
              >
                <i class="mdi mdi-format-list-bulleted-square"></i>
                Questões
              </router-link>
            </li>

            <li class="nav-item display-none">
              <router-link
                tag="a"
                class="nav-link side-nav-link"
                :to="{ name: 'reports' }"
              >
                <i class="mdi mdi-format-list-bulleted-square"></i>
                Informativos de Jurisprudência
              </router-link>
            </li>

            <li class="nav-item">
              <router-link
                tag="a"
                class="nav-link side-nav-link"
                :to="{ name: 'conversations' }"
              >
                <i class="mdi mdi-message-text"></i>
                Conversas
              </router-link>
            </li>

            <li class="nav-item">
              <router-link
                tag="a"
                class="nav-link side-nav-link"
                :to="{ name: 'rankings' }"
              >
                <i class="mdi mdi-chart-timeline-variant"></i>
                Rankings
              </router-link>
            </li>

            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle arrow-none"
                href="javascript: void(0);"
                id="topnav-layout"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="onMenuClick"
              >
                <i class="ti-bookmark-alt mr-2"></i>Professores
              </a>
              <div class="dropdown-menu" aria-labelledby="topnav-layout">
                <router-link
                  class="side-nav-link dropdown-item"
                  href="javascript: void(0);"
                  id="topnav-layout"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  :to="{ name: 'selectMentor' }"
                >
                  <i class="mdi mdi mdi-help-circle"></i>
                  Ver professores
                </router-link>

                <router-link
                  v-if="currentUser.mentorLevel > 0"
                  tag="a"
                  class="side-nav-link dropdown-item"
                  :to="{ name: 'strayUsers' }"
                >
                  <i class="mdi mdi mdi-account-group"></i>
                  Usuários sem professores
                </router-link>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
import { layoutMethods } from "@/state/helpers";

import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("auth", ["currentUser"]),
  },

  mounted() {
    var links = document.getElementsByClassName("side-nav-link");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
        }
        const parent3 = parent2.parentElement;
        if (parent3) {
          parent3.classList.add("active");
          var childAnchor = parent3.querySelector(".has-dropdown");
          if (childAnchor) childAnchor.classList.add("active");
        }

        const parent4 = parent3.parentElement;
        if (parent4) parent4.classList.add("active");
        const parent5 = parent4.parentElement;
        if (parent5) parent5.classList.add("active");
      }
    }
  },
  methods: {
    ...layoutMethods,

    /**
     * menu clicked show the subelement
     */
    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling;
      if (nextEl && !nextEl.classList.contains("show")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("show");
        }
        nextEl.classList.add("show");
      } else if (nextEl) {
        nextEl.classList.remove("show");
      }
      return false;
    },

    topbarLight() {
      document.body.setAttribute("data-topbar", "light");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedWidth() {
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-topbar", "light");
      document.body.setAttribute("data-topbar", "dark");
    },
    changeLayout(layout) {
      this.changeLayoutType({ layoutType: layout });
    },
  },
};
</script>

<style>
.topnav .navbar-nav .nav-link:focus,
.topnav .navbar-nav .nav-link:hover {
  color: unset;
}

.display-none {
  display: none;
}
</style>
