var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{attrs:{"id":"page-topbar"}},[_c('div',{staticClass:"navbar-header"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"navbar-brand-box"},[_c('router-link',{staticClass:"logo logo-dark",attrs:{"to":{ name: 'dashboard' }}},[_c('span',{staticClass:"logo-sm"},[_c('h2',{staticClass:"text-dark py-3"},[_vm._v("QPRO")])]),_c('span',{staticClass:"logo-lg"},[_c('h2',{staticClass:"text-dark py-3"},[_vm._v("QuestõesPRO")])])]),_c('router-link',{staticClass:"logo logo-light",attrs:{"to":{ name: 'dashboard' }}},[_c('span',{staticClass:"logo-sm"},[_c('img',{attrs:{"src":require("@/assets/images/logo-sm.png"),"alt":"","height":"22"}})]),_c('span',{staticClass:"logo-lg"},[_c('img',{attrs:{"src":require("@/assets/images/logo-light.png"),"alt":"","height":"18"}})])])],1),_c('button',{staticClass:"btn btn-sm mr-2 font-size-24 d-lg-none header-item",attrs:{"type":"button"},on:{"click":_vm.toggleMenu}},[_c('i',{staticClass:"mdi mdi-menu"})])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"dropdown d-none d-lg-inline-block"},[_c('button',{staticClass:"btn header-item noti-icon",attrs:{"type":"button","title":"Ir para a tela cheia"},on:{"click":_vm.initFullScreen}},[_c('i',{staticClass:"mdi mdi-fullscreen"})])]),_c('NotificationList'),(_vm.currentUser)?_c('b-dropdown',{staticClass:"d-inline-block",attrs:{"right":"","toggle-class":"header-item","variant":"white"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('img',{staticClass:"rounded-circle header-profile-user",attrs:{"src":_vm.currentUser.avatar
                ? _vm.$getImageUrl(_vm.currentUser.avatar.originalName)
                : _vm.$defaultUserAvatar,"alt":"Header Avatar"}})]},proxy:true}],null,false,797048938)},[_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{
            name: 'profile',
            params: { username: _vm.currentUser.username },
          }}},[_c('i',{staticClass:"mdi mdi-account-circle font-size-17 align-middle mr-1"}),_vm._v(" Perfil ")]),_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{
            name: 'myInterests',
          }}},[_c('i',{staticClass:"mdi mdi-view-list font-size-17 align-middle mr-1"}),_vm._v(" Meus Interesses ")]),_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{ name: 'editProfile' }}},[_c('i',{staticClass:"mdi mdi-pencil-outline font-size-17 align-middle mr-1"}),_vm._v(" Editar Perfil ")]),_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{ name: 'plan' }}},[_c('i',{staticClass:"mdi mdi-coin-outline font-size-17 align-middle mr-1"}),_vm._v(" Planos ")]),_c('div',{staticClass:"dropdown-divider"}),_c('router-link',{staticClass:"dropdown-item text-danger",attrs:{"to":{ name: 'logout' }}},[_c('i',{staticClass:"mdi mdi-logout align-middle mr-1 text-danger"}),_vm._v(" Logout ")])],1):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }