<template>
  <b-dropdown
    class="d-inline-block"
    toggle-class="header-item noti-icon"
    menu-class="dropdown-menu-lg p-0"
    right
    variant="white"
  >
    <template v-slot:button-content>
      <i class="mdi mdi-bell-outline"></i>
      <span v-if="notifications.length" class="badge badge-danger badge-pill">{{
        notifications.length
      }}</span>
    </template>

    <div class="p-3">
      <div class="row">
        <div class="col-12">
          <h5 class="mb-2 font-size-16">
            <router-link :to="{ name: 'viewNotifications' }">
              Notificações ({{ notifications.length }})
            </router-link>
          </h5>
        </div>
        <div class="col-12 text-center">
          <button
            v-if="notifications.length !== 0"
            @click.prevent="readAllNotifications()"
            class="btn btn-outline-primary btn-sm btn-block mx-auto d-block"
          >
            <i class="mdi mdi-check-bold"></i> Marcar todas como lidas
          </button>
          <h6 v-if="notifications.length === 0" class="text-center my-2">
            <i class="mdi mdi-alert-circle"></i> Nenhuma notificação
            encontrada!!
          </h6>
        </div>
      </div>
    </div>
    <div data-simplebdar style="max-height: 230px; overflow-y: auto;">
      <router-link
        v-for="notification in notifications"
        :key="notification.id"
        :to="
          notification.routeName
            ? { name: notification.routeName, params: notification.routeParams }
            : '#'
        "
        @click.native="() => readNotification(notification)"
        class="text-reset notification-item"
      >
        <div class="media">
          <div v-if="false" class="avatar-xs mr-3">
            <img
              class="rounded-circle header-profile-user"
              :src="
                currentUser.avatar
                  ? $getImageUrl(currentUser.avatar.originalName)
                  : $defaultUserAvatar
              "
              alt="Header Avatar"
            />
          </div>

          <div class="media-body">
            <h6 class="mt-0 mb-1">{{ notification.title }}</h6>
            <div class="font-size-12 text-muted">
              <p class="mb-1">{{ notification.content }}</p>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </b-dropdown>
</template>

<script>
import { mapState } from "vuex";
import * as api from "@/api";

export default {
  name: "NotificationList",

  data() {
    return {
      notifications: [],
      seenNotifications: [],
      timeout: false,
    };
  },

  created() {
    if (!this.currentUser) {
      return;
    }
    this.loadNotifications(true);
  },

  beforeDestroy() {
    clearTimeout(this.timeout);
  },

  computed: {
    ...mapState("auth", ["currentUser"]),

    getNotificationNumber() {
      return this.notifications.length;
    },
  },

  methods: {
    scheduleLoad() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.loadNotifications();
      }, 10000);
    },

    loadNotifications(firstTime = false) {
      if (!this.currentUser) {
        return this.scheduleLoad();
      }

      api
        .getNotifications()
        .then((notifications) => {
          this.notifications = notifications;
          if (firstTime) {
            this.seenNotifications = notifications.map((n) => n.id);
            return;
          }
          for (const notification of notifications) {
            this.toast(notification);
          }
        })
        .finally(() => {
          this.scheduleLoad();
        });
    },

    readNotification(notification) {
      this.notifications = this.notifications.filter(
        (n) => n.id !== notification.id
      );
      api.readNotification(notification.id);
    },

    async readAllNotifications() {
      await api.readNotification("all");
      this.notifications = [];
    },

    toast(notification) {
      if (this.seenNotifications.includes(notification.id)) {
        return;
      }
      this.seenNotifications.push(notification.id);
      this.$bvToast.toast(notification.content, {
        title: notification.title,
        variant: "info",
        toaster: "b-toaster-bottom-right",
        solid: true,
        autoHideDelay: 12000,
        to: notification.routeName
          ? {
              name: notification.routeName,
              params: notification.routeParams,
            }
          : undefined,
      });
    },
  },
};
</script>
